import { Button, Group, Table, TableData, Text, TextInput, Title } from '@mantine/core';
import { useSearchParams } from 'react-router-dom';
// MSAL imports
import { AuthenticationResult } from '@azure/msal-browser';
import { useEffect, useState } from 'react';
import { useIEHR } from '@iehr/react';
import { modals } from '@mantine/modals';
import { ContentType, MailOptions } from '@iehr/core';
import { Project } from '@iehr/fhirtypes';
import { popupCenter } from '../utils';

export interface GoogleOptions {
  readonly redirectUri: string;
  readonly renew?: boolean;
  readonly code?: string;
}

export function GoogleRedirectPage(): JSX.Element {
  const iehr = useIEHR();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  //http://localhost:3000/admin/google?code=4/0AcvDMrDVjd95mQjg2vk3i9CYeHN7TDtMyqYxFVaamfzxBhCpSd018daXoFAW43AleRixpA&scope=https://mail.google.com/%20https://www.googleapis.com/auth/contacts%20https://www.googleapis.com/auth/calendar
  console.log('GoogleRedirectPage', searchParams);

  useEffect(() => {
    if (!iehr && !code) {
      return;
    }
    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    iehr
      .post(
        'admin/google/redirect',
        {
          code: code,
        },
        ContentType.JSON
      )
      .then((authResult: AuthenticationResult) => {
        console.log('GoogleRedirectPage', authResult);
        window.close();
        //navigate('/admin/google');
      });
  }, [iehr, code]);

  return <></>;
}

export function GooglePage(): JSX.Element {
  const iehr = useIEHR();
  const [tokenSet, setTokenSet] = useState<any>();
  const [userInfo, setUserInfo] = useState<any>();
  const [project, setProject] = useState<Project>();
  const [tableData, setTableData] = useState<TableData>();
  const [testEmailFrom, setTestEmailFrom] = useState<string>();
  const [testEmailTo, setTestEmailTo] = useState<string>();
  const [testEmail, setTestEmail] = useState<boolean>();

  useEffect(() => {
    if (!iehr) {
      return;
    }
    iehr
      .readResource('Project', iehr.getProject()?.id!)
      .then((project) => setProject(project))
      .catch();
  }, [iehr]);

  const handleLogin = async (): Promise<void> => {
    console.log('admin/google/authorize');
    iehr
      .post('admin/google/authorize', {
        redirectUri: 'admin/google/redirect',
      })
      .then((redirectUrl: string) => {
        //
        console.log(redirectUrl);
        //window.location.href = redirectUrl;
        const popup = popupCenter(redirectUrl, 'Google Login',500,700);
        const checkPopup = setInterval(() => {
          if (!popup || !popup.closed) return;
          clearInterval(checkPopup);
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (iehr && project) {
      let serialized = project.secret?.find((s) => s.name === 'GOOGLE_TOKENS')?.valueString;
      if (serialized) {
        setTokenSet(JSON.parse(serialized));
      }

      serialized = project.secret?.find((s) => s.name === 'GOOGLE_ME')?.valueString;
      if (serialized) {
        setUserInfo(JSON.parse(serialized));
      }
    }
  }, [iehr, project]);

  useEffect(() => {
    if (userInfo) {
      setTestEmailFrom(userInfo.email);
      const body: React.ReactNode[][] = [];
      Object.keys(userInfo).forEach(key=>{
        if(typeof userInfo[key] === 'string'){
          body.push([key,userInfo[key]])
        }
      })
      setTableData({
        body,
      });
    }
  }, [userInfo]);

  useEffect(() => {
    if (!testEmail) {
      return;
    }
    setTestEmail(false);

    if (!validateEmail(testEmailTo)) {
      console.log('Wrong Email Format');
      return;
    }
    console.log('admin/google/sendmail');
    const options: MailOptions = {
      from: testEmailFrom,
      to: testEmailTo,
      subject: 'Hello',
      text: 'Hello from iEHR',
    };
    iehr
      .post('admin/google/sendMail', {
        options,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [testEmail, testEmailTo]);

  const validateEmail = (email: string | undefined) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleLogout = () =>
    modals.openConfirmModal({
      title: 'Revoke HealthMail Authorization',
      children: <Text size="sm">Are you sure?</Text>,
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => {
        iehr.post('admin/google/unauthorize', {}).then(()=>{
          window.location.reload();
        })
        
      },
    });

  const showSendTestEmail = () => {
    setTestEmail(false);
    setTestEmailTo('');
    modals.openConfirmModal({
      title: 'Send Test Email',
      children: (
        <>
          <TextInput
            style={{ width: 180 }}
            defaultValue={'@' + testEmailFrom?.split('@')[1]}
            onChange={(e) => setTestEmailTo(e.currentTarget.value)}
          />
        </>
      ),
      labels: { confirm: 'Send', cancel: 'Cancel' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => setTestEmail(true),
    });
  };

  return (
    <>
      <Title>Google Account</Title>
      {!!tokenSet && (
        <>
          <Table data={tableData} />
          <Group justify="right">
            <Button  onClick={showSendTestEmail}>
              Send Test Email
            </Button>
            <Button onClick={handleLogout}>Revoke</Button>
          </Group>
        </>
      )}
      {!tokenSet && (
        <>
          <Text variant="h6">
            Please authorize your google account to receive calendar updates.
          </Text>
          <Button onClick={handleLogin}>Authorize</Button>
        </>
      )}
    </>
  );
}
